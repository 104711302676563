<template>
  <div class="home-layout withTopBar">
    <van-nav-bar title="促销助手" safe-area-inset-top fixed></van-nav-bar>
    <div class="home-top flex_box space_between">
      <div class="">
        <div class="text">{{user.station_name}}</div>
        <div class="text">促销员：{{user.name}}</div>
      </div>
      <div>
        <div class="day">{{nowDate}}</div>
        <div class="quit" @click="quit">退出</div>
      </div>
      <div class="bg">
        <img :src="baseUrl+'ad_bg.png'">
      </div>
    </div>
    <div class="home-menu flex_box flex_wrap holder">
      <div class="item" @click="jumpPage('product-list')">
        <img :src="baseUrl+'icon_1.png'">
        <div class="name">产品列表</div>
      </div>
      <div class="item" @click="jumpPage('order-list')">
        <img :src="baseUrl+'icon_2.png'">
        <div class="name">订单查询</div>
      </div>
      <div class="item" @click="jumpPage('pre-storage')">
        <img :src="baseUrl+'icon_3.png'">
        <div class="name">鲜奶预存</div>
      </div>
      <div class="item" @click="jumpPage('user-list')">
        <img :src="baseUrl+'icon_4.png'">
        <div class="name">预填信息</div>
      </div>
      <div class="item" @click="jumpPage('collection')">
        <img :src="baseUrl+'icon_5.png'">
        <div class="name">收款记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurData } from "@/utils/common"
export default {
  name:"home",
  data(){
    return{
      baseUrl: process.env.BASE_URL,
      user:{
        station_name:"暂无",
        name:"暂无"
      }
    }
  },
  created(){
    let user = localStorage.getItem("cxyUserinfo")
    console.log(user)
    user ? this.user = JSON.parse(user) : ''
  },
  methods:{
    quit(){
      this.$store.commit('logout', this)   
    },
    jumpPage(name){
      console.log(name)
      this.$router.push({
        name: name,
      })
    }
  },
  computed: {
    nowDate(){
      return getCurData('/')
    }
  }
}
</script>


<style lang="scss">
@import "./home.scss";
</style>